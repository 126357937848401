<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="init" class="cur-a">班级列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          " style="align-items: flex-start">
          <div class="searchbox" style="width: 100%">
            <div>
              <div title="班级编码" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级编码:</span>
                <el-input v-model="projectCode" type="text" size="small" placeholder="请输入班级编码 "
                  onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" clearable />
              </div>
              <div title="班级名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级名称:</span>
                <el-input v-model="projectName" type="text" size="small" placeholder="请输入班级名称" clearable
                  style="flex: 1" />
              </div>
              <div class="df">
                <transition name="el-zoom-in-center">
                  <el-button type="primary" class="bgc-bv" round @click="() => {
                      getData();
                    }
                    " v-if="flag == false">查询</el-button>
                </transition>
              </div>
              <div style="margin-left: 10px">
                <el-button @click="search()" size="small" type="text">
                  高级搜索
                  <i :class="flag ? 'el-icon-top' : 'el-icon-bottom'"></i>
                </el-button>
              </div>
            </div>
            <div class="btnBox">
              <el-button type="primary" class="bgc-bv" round @click="addTestPaper">新建班级</el-button>
              <el-button type="primary" class="bgc-bv" round @click="quickStart">快速开班</el-button>
              <el-button class="bgc-bv" round @click="open" size="small">自定义表头</el-button>
            </div>
          </div>
          <el-collapse-transition>
            <div>
              <div class="searchbox" style="margin-top: 10px; width: 100%" v-show="flag == true">
                <div class="searchbox">
                  <div title="负责人" class="searchboxItem">
                    <span class="itemLabel">负责人:</span>
                    <el-input v-model="ruleForm.projectUser" type="text" size="small" placeholder="请输入负责人" clearable />
                  </div>
                  <div title="班级状态" class="searchboxItem">
                    <span class="itemLabel">班级状态:</span>
                    <el-select size="small" v-model="ruleForm.Classstatus" placeholder="请选择班级状态" clearable>
                      <el-option v-for="item in ClassstatusList" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </div>
                  <div title="行政区划" class="searchboxItem">
                    <span class="itemLabel">行政区划:</span>
                    <el-cascader clearable filterable v-model="ruleForm.areaId" :options="areatreeList" :props="propsarea"
                      size="small"></el-cascader>
                  </div>
                  <div title="负责人电话" class="searchboxItem">
                    <span class="itemLabel projectUser">负责人电话:</span>
                    <el-input v-model="ruleForm.projectUserPhone" type="text" size="small" placeholder="请输入负责人电话"
                      clearable />
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
          <el-collapse-transition>
            <div class="searchbox" style="margin-top: 10px; width: 100%" v-show="flag == true">
              <div class="searchbox">
                <div title="培训类型" class="searchboxItem">
                  <span class="itemLabel">培训类型:</span>
                  <tree ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" size="small" @clearParams="clearParams"
                    typeStu />
                </div>
                <div title="开班时间" class="searchboxItem ci-full">
                  <span class="itemLabel">开班时间:</span>
                  <el-date-picker v-model="ruleForm.opentime" type="daterange" size="small" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" />
                </div>
                <div title="结束时间" class="searchboxItem ci-full">
                  <span class="itemLabel">结束时间:</span>
                  <el-date-picker clearable size="small" v-model="ruleForm.endTime" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"></el-date-picker>
                </div>
                <div>
                  <el-button type="primary" class="bgc-bv" round @click="() => {
                      getData();
                    }
                    ">查询</el-button>
                </div>
                <div style="margin-left: 10px">
                  <el-button type="text" round @click="resetForm">清空搜索条件</el-button>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
        <div style="margin: 15px 0 5px">
          <span>班级总数：{{ projectNum }}</span>
          <span style="margin: 0 30px">班级总人次：{{ projectPeopleNum }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe v-if="showtable">
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="200px"
                fixed="left" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="250px"
                fixed="left" />
              <template v-if="tableHaed.length">
                <el-table-column v-for="(el, index) in tableHaed" :key="index" :label="el.label" :align="el.textAlign"
                  show-overflow-tooltip :min-width="el.width">
                  <template slot-scope="scope">{{
                    getValue(el, scope.row)
                  }}</template>
                </el-table-column>
              </template>
              <el-table-column label="操作" align="left" width="330px" fixed="right">
                <template slot-scope="scope">
                  <div>
                    <el-button type="text" size="mini" style="padding: 0 5px"
                      @click="seeDetails(scope.row)">详情</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px"
                      @click="jumpclassList(scope.row)">课程列表</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px"
                      @click="jumpStudentList(scope.row)">学员列表</el-button>
                    <el-button type="text" style="padding: 0 5px" @click="
                      payOrder(scope.row.projectId, scope.row.projectName)
                      " :disabled="scope.row.courseCount == 0 ||
                        scope.row.projectSource == '20' ||
                        scope.row.projectSource == '60'
                        " size="mini">班级二维码</el-button>
                    <el-button type="text" style="padding: 0 5px" :disabled="scope.row.auditState != '30'"
                      @click="extensionApplication(scope.row)" size="mini">延期申请</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px" @click="deleteproject(scope.row.projectId)"
                      :disabled="scope.row.auditState == '20' ||
                        scope.row.auditState == '25' ||
                        scope.row.auditState == '30' ||
                        scope.row.projectSource == '20'
                        ">删除</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px" @click="
                      keeprecord(
                        scope.row.projectId,
                        scope.row.projectSource,
                        scope.row.courseCount,
                        scope.row
                      )
                      " :disabled="scope.row.auditState == '20' ||
                        scope.row.auditState == '25' ||
                        scope.row.auditState == '30'
                        ">开班申请</el-button>
                    <el-button type="text" style="padding: 0 5px" size="mini" @click="showDocsDialog(scope.row)"
                      :disabled="scope.row.projectStudyTimeState == '10'">培训档案</el-button>
                    <el-button type="text" style="padding: 0 5px" @click="classDaily(scope.row)"
                      size="mini">班级日报码</el-button>
                    <el-button type="text" style="padding: 0 5px" @click="copyClass(scope.row)"
                      size="mini" :disabled="scope.row.projectSource != '10'">复制班级</el-button>
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog width="500px" :title="innerTitle" @close="closeCode" :visible.sync="innerVisible">
      <div class="qrcode">
        <!-- 放置二维码的容器,需要给一个ref -->
        <div id="qrcode" ref="qrcode"></div>
      </div>
      <div class="refreshcode">
        <el-button class="bgc-bv" @click="refreshCode">重新生成班级二维码</el-button>
      </div>
    </el-dialog>
    <el-dialog title="培训档案选择" :visible.sync="docsDialogVisible" width="50%" top="5%" :center="true"
      :before-close="closeDocsDialog">
      <div>
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form ref="docsFormnew" :model="docsFormnew" class="docsDialog" v-show="danganList.length">
              <el-form-item v-for="(item, index) in danganList" :key="index">
                <el-checkbox v-model="docsFormnew[item.dictCode]">{{
                  item.dictValue
                }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog title="自定义表头" :visible.sync="tableHeadDialog" width="30%" class="custom" @close="cencal">
      <p style="padding-left: 20px; margin-bottom: 10px">
        勾选需要显示在列表上的项，可拖动调整顺序
      </p>
      <div>
        <el-tree ref="headTree" :data="treeDataCopy" default-expand-all draggable :allow-drop="allowDrop"
          :allow-drag="allowDrag">
          <template slot-scope="{ node, data }">
            <el-checkbox @change="(val) => {
                handleCheckChange(val, data);
              }
              " v-model="data.checked" :disabled="data.label == '课程名称'">{{ node.label }}</el-checkbox>
          </template>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencal">取 消</el-button>
        <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 班级日报 -->
    <el-dialog width="820px" title="班级日报" modal="false" :visible.sync="innerClassDaily" @close="innerClassDaily = false">
      <div>
        <div style="display: flex">
          <div class="leftData">
            <span style="color: tomato; font-size: 1.25rem">{{
              projectNames
            }}</span>
            <img :src="classImg" alt />
            <span>该二维码24小时内（{{ this.Spare }}前）有效</span>
          </div>
          <div class="dailyRemind">
            <div class="dailyRemind-1">
              <h3>微信扫码订阅班级日报</h3>
              <p>微信可接收该班级的学员日报并可向学员推送消息</p>
            </div>
            <div class="dailyRemind-2">
              <h3>订阅日报步骤</h3>
              <p>1· 订阅日报人员打开微信扫一扫，扫描左侧二维码。</p>
              <p>2· 扫描后关注“安知平台”公众号。</p>
              <p>
                3· 关注后点击【立即绑定】，填写身份证号、姓名和手机号进行绑定。
              </p>
              <p>4· 已经绑定的用户，点击【立即查看】，查看班级日报。</p>
            </div>
            <!-- <div class="dailyRemind-2">
              <h3>小程序订阅日报步骤</h3>
              <p>
                1·
                点击【订阅】按钮，输入用户的身份证号、姓名和手机号点击【立即绑定】。
              </p>
              <p>
                2·
                绑定成功的用户，微信搜索【安知课堂】小程序，切换用户类型为【机构老师】，点击【学习管理】，即可查看班级日报。
              </p>
              <p>3· 已通过公众号订阅的用户，直接使用小程序即可查看。</p>
            </div> -->
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <div style="
              display: flex;
              margin-bottom: 20px;
              justify-content: space-between;
              align-items: center;
            ">
            <h3 style="">订阅班级日报人员</h3>
            <el-button class="bgc-bv" size="small" @click="subscribe">订阅</el-button>
          </div>
          <el-table ref="multipleTable" :data="tableClassData" height="200px" size="small" tooltip-effect="dark"
            style="width: 100%" :header-cell-style="tableHeader" stripe>
            <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" min-width="150px" />
            <el-table-column label="手机" align="left" show-overflow-tooltip prop="mobile" min-width="150px" />
            <el-table-column label="操作" align="center" width="270px" fixed="right">
              <div slot-scope="scope">
                <el-button type="text" style="padding: 0 5px"
                  @click="classDailyClose(scope.row.subId, scope.row.userName)" size="mini">解绑</el-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="800px" title="提示" :visible.sync="paraIsPopDialog" top="2%">
      <p style="font-size: 1rem; padding: 15px 1rem">
        提示：根据监管要求每学时弹题<span style="color: #f56c6c; font-size: 1.25rem">{{ popNum }}</span>次，课件弹题配置题目数量少于课件学时*<span
          style="color: #f56c6c; font-size: 1.25rem">{{ popNum }}</span>道(四舍五入)，不合格课件列表如下:
      </p>
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="retData" height="500px" size="small" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="tableHeader" stripe>
          <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
          <el-table-column label="课件名称" align="center" show-overflow-tooltip prop="courseName" min-width="150px" />
          <el-table-column label="应配弹题题目(道)" align="center" show-overflow-tooltip prop="minPopNum" min-width="150px" />
          <el-table-column label="实际配弹题题目(道)" align="center" show-overflow-tooltip prop="currPopNum" min-width="150px" />
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <!-- 山西 -->
    <el-dialog width="800px" title="提示" :visible.sync="SxDialog" top="2%">
      <p style="font-size: 1rem; padding: 15px 1rem">
        提示：根据监管要求课后习题必须等于<span style="color: #f56c6c; font-size: 1.25rem">{{ homeworkLimit }}</span>道，总分必须为<span
          style="color: #f56c6c; font-size: 1.25rem">{{
            100
          }}</span>分；阶段性测试不少于<span style="color: #f56c6c; font-size: 1.25rem">{{
            unitLimit
          }}</span>道，及格分数不能低于总分的<span style="color: #f56c6c; font-size: 1.25rem">{{ qualifiedLimit * 100
          }}%</span>，试卷内题目分值<span style="color: #f56c6c; font-size: 1.25rem">相同</span>；课件时长必须大于<span
          style="color: #f56c6c; font-size: 1.25rem">{{
            45
          }}</span>分钟小于<span style="color: #f56c6c; font-size: 1.25rem">{{
            60
          }}</span>分钟，不合格列表如下:
      </p>
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="retData" height="500px" size="small" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="tableHeader" stripe>
          <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
          <el-table-column label="名称" align="center" show-overflow-tooltip prop="paperName" min-width="150px" />
          <el-table-column label="类型" align="center" show-overflow-tooltip prop="errorType" min-width="150px">
            <template slot-scope="scope">{{ scope.row.errorType == '20' ? '课件' : '试卷' }} </template>
          </el-table-column>
          <el-table-column label="不合格t原因" align="center" show-overflow-tooltip prop="errorReason" min-width="150px" />
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <p style="padding-bottom: 1rem">请核对班级行政区划和期次</p>
      <div style="display: flex; align-items: center; text-align: right" class="el-dialogs">
        <span class="itemLabel" style="min-width: 6rem">班级行政区划:</span>
        <el-cascader clearable filterable v-model="areaIdCompId" :options="areatreeList" :props="propsarea"
          size="small"></el-cascader>
      </div>
      <div style="
          display: flex;
          align-items: center;
          margin-top: 1rem;
          text-align: right;
        " class="el-dialogs">
        <span class="itemLabel" style="min-width: 6rem">期次:</span>
        <el-input-number v-model="Periodnum" @change="handleChange" :min="1" size="small">
        </el-input-number>
        <span>期</span>
      </div>
      <p style="color: #dd1d35">
        注意:请准确填写期次,数据会显示补贴标准申请材料中
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="areaIdSure" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 订阅 -->
    <el-dialog title="订阅日报" :visible.sync="subscribedialogVisible" width="30%" :before-close="handlesubscribeClose">
      <div class="el-dialogs">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="联系方式" prop="mobile">
            <el-input @input="getPhone_info" v-model="form.mobile" size="small"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="form.userName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idcard">
            <el-input v-model="form.idcard" size="small"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="getSure('form')" size="small">立即绑定</el-button>
      </span>
    </el-dialog>
    <!-- 延期申请 -->
    <el-dialog title="延期申请" :visible.sync="extensiondialogVisible" width="400" :before-close="handleExtensionClose">
      <div class="el-dialogs ovy-a" style="height: 500px">
        <el-form ref="extensionform" :model="extensionForm" label-width="120px" :rules="extensionRules">
          <el-form-item label="班级名称:" prop="projectName">
            <p>{{ extensionForm.projectName }}</p>
          </el-form-item>
          <el-form-item label="原结束日期:" prop="originalEndDate">
            <el-date-picker size="small" v-model="extensionForm.originalEndDate" type="date" disabled format="yyyy-MM-dd"
              value-format="yyyy-MM-dd" placeholder="请选择原结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="申请结束日期:" prop="applicationEndDate">
            <el-date-picker size="small" v-model="extensionForm.applicationEndDate" type="date" format="yyyy-MM-dd"
              value-format="yyyy-MM-dd" placeholder="请选择申请结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="上传附件:" :required="extensionForm.trainTypeId == '5' ||
              extensionForm.trainTypeId == '15'
              ? true
              : false
            ">
            <div style="display: flex; align-items: center">
              <el-upload class="upload-demo upload-btn" :action="actionUrl" :on-error="handleError"
                :on-success="handleSuccess" :on-change="uploadChange" multiple :limit="5" :file-list="fileList"
                :show-file-list="false" :auto-upload="false">
                <el-button class="bgc-bv" size="mini">上传附件</el-button>
              </el-upload>
              <span style="color: #f46173">支持上传jpg、png、pdf文件，最多上传5个文件。</span>
            </div>
            <div style="display: flex; flex-direction: column">
              <span style="color: #409eff; text-align: left" v-for="(item, index) in NameList" :key="index">
                {{ item.delayFileName }}
                <a @click="jumpLook(item)" style="color: #409eff; margin: 0 15px">预览</a>
                <a @click="reomveExl(index)" style="color: red; margin: 0 15px">删除</a>
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="handleExtensionClose('form')" size="small">取消</el-button>
        <el-button class="bgc-bv" @click="getExtensionSure('extensionform')" size="small">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog title="预览" :visible.sync="previewLoding1" @close="uploadLodClose" width="50%" top="2%" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="fileType == 'pdf' || fileType == 'PDF'"></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classListCtr",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      danganList: [], // 档案列表
      docsFormnew: {},
      classImg: "", //二维码地址
      tableClassData: [], //班级日报，弹框table数据
      projectIdSel: "", //选择的班级ID
      showtable: false,
      innerVisible: false,
      innerClassDaily: false, //班级日报弹窗
      paraIsPopDialog: false, //弹题错误数据
      dialogVisible: false,
      Periodnum: 1,
      SxDialog: false, //山西
      areaIdCompId: "",
      innerTitle: "",
      // dialogVisible: false,
      areatreeList: [],
      ClassstatusList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      projectName: "",
      projectCode: "",
      ruleForm: {
        areaId: "",
        projectUser: "",
        projectUserPhone: "",
        Classstatus: "",
        opentime: "",
        endTime: "",
        Trainingtype: "",
      },
      docsDialogVisible: false,
      agreeState: false, //弹框是否同意
      selectedItem: {},
      params: {},
      projectNum: "", //班级总数：
      projectPeopleNum: "", //班级总人次：
      flag: false,

      // 自定义表头
      tableHeadDialog: false, //
      treeData: [
        {
          label: "行政区划", //表头名称
          propName: "areaNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 1,
          id: "1",
          checked: true,
        },
        {
          label: "培训类型", //表头名称
          propName: "trainTypeNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 2,
          id: "2",
          checked: true,
        },
        {
          label: "行业类型", //表头名称
          propName: "industryNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 3,
          id: "3",
          checked: true,
        },
        {
          label: "岗位类型", //表头名称
          propName: "postName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 4,
          id: "4",
          checked: true,
        },
        {
          label: "职业/工种", //表头名称
          propName: "occupationNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 5,
          id: "5",
          checked: true,
        },
        {
          label: "培训等级", //表头名称
          propName: "trainLevelName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 6,
          id: "6",
          checked: true,
        },
        {
          label: "负责人", //表头名称
          propName: "projectUser", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 7,
          id: "7",
          checked: true,
        },
        {
          label: "负责人电话", //表头名称
          propName: "projectUserPhone", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 8,
          id: "8",
          checked: true,
        },
        {
          label: "开始时间", //表头名称
          propName: "startDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 9,
          id: "9",
          checked: true,
        },
        {
          label: "结束时间", //表头名称
          propName: "endDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 10,
          id: "10",
          checked: true,
        },
        {
          label: "报名截止", //表头名称
          propName: "signEndDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 11,
          id: "11",
          checked: true,
        },
        {
          label: "班级状态", //表头名称
          propName: "auditState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "AUDITSTATE", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },
        {
          label: "班级总人数", //表头名称
          propName: "projectPeopleNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 13,
          id: "13",
          checked: true,
        },
        {
          label: "课程总人次", //表头名称
          propName: "projectCourseNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 14,
          id: "14",
          checked: true,
        },
        {
          label: "延期审核结果", //表头名称
          propName: "delayAuditType", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "DELAY_AUDIT_TYPE", //字典对应key
          width: "140", // 列的宽
          order: 15,
          id: "15",
          checked: true,
        },
      ],
      tableHaed: [],
      treeDataCopy: [],
      Spare: "",
      retData: [],
      popNum: "",
      area: {},
      homeworkLimit: "",
      qualifiedLimit: "",
      unitLimit: "",
      subscribedialogVisible: false,
      form: {
        mobile: "",
        userName: "",
        idcard: "",
      },
      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idcard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
      },
      projectNames: "",
      extensiondialogVisible: false, //申请延期弹窗
      extensionForm: {
        projectName: "",
        originalEndDate: "",
        applicationEndDate: "",
      },
      extensionRules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        originalEndDate: [
          { required: true, message: "请输入原结束日期", trigger: "blur" },
        ],
        applicationEndDate: [
          { required: true, message: "请输入申请结束日期", trigger: "blur" },
        ],
      },
      NameList: [],
      fileList: [],
      chatFileKeys: [],
      pickerOptions0: this.processDate(),
      previewLoding1: false,
      ImgSrc: "",
    };
  },
  created() {
    this.getTableHeight();
    this.getareatree();
    this.getclassstatusList();
    this.getTableHead();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() { },
  methods: {
    processDate() {
      let self = this;
      return {
        disabledDate(time) {
          if (self.extensionForm.originalEndDate) {
            return (
              new Date(self.extensionForm.originalEndDate).getTime() >=
              time.getTime() ||
              time.getTime() >= self.extensionForm.originalEndDate
            );
          } else {
            return time.getTime() >= self.extensionForm.originalEndDate; //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        if (el.propName == "auditState") {
          if (
            row.auditState == "10" ||
            row.auditState == "20" ||
            row.auditState == "40"
          ) {
            return this.$setDictionary("AUDITSTATE", row.auditState) == "未提审"
              ? "未申请"
              : this.$setDictionary("AUDITSTATE", row.auditState) ==
                "审核未通过"
                ? "未通过"
                : this.$setDictionary("AUDITSTATE", row.auditState);
          } else if (
            row.auditState == "30" &&
            row.projectStudyTimeState != "10"
          ) {
            return this.$setDictionary(
              "PROJECTSTATE",
              row.projectStudyTimeState
            ) == "审核未通过"
              ? "未通过"
              : this.$setDictionary("PROJECTSTATE", row.projectStudyTimeState);
          } else if (row.auditState == "30") {
            return this.$setDictionary("AUDITSTATE", row.auditState);
          }
          if (row.auditState == "25") {
            return "运营审核通过";
          } else {
            return this.$setDictionary(el.dictionaries, row[el.propName]);
          }
        } else {
          return this.$setDictionary(el.dictionaries, row[el.propName]);
        }
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
            : "--";
        } else if (el.timeType == "momentDate") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD")
            : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "classListCtr",
      }).then((res) => {
        // ;
        this.getTableHead();
      });
    },
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "classListCtr",
      })
        .then((res) => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map((el) => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              this.handle();
            }
          } else {
            this.handle();
            // this.tableHaed = [...this.treeData];
          }
        })
        .catch((err) => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (draggingNode.label == "课程名称") {
        return false;
      }
      return true;
    },
    handleCheckChange(val, data) {
      // console.log(data);
    },
    //获取班级总数和班级总人次
    getTotal(params) {
      // const params = {};

      this.$post("/biz/project/queryProjectTotalAndPeopleTotal", params, false)
        .then((ret) => {
          this.projectNum = ret.data.projectNum;
          this.projectPeopleNum = ret.data.projectPeopleNum;
        })
        .catch((err) => {
          return;
        });
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectName) {
        params.projectName = this.projectName.trim();
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode.trim();
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.projectUser) {
        params.projectUser = this.ruleForm.projectUser;
      }
      if (this.ruleForm.projectUserPhone) {
        params.projectUserPhone = this.ruleForm.projectUserPhone;
      }
      if (this.ruleForm.Classstatus) {
        params.projectStudyTimeState = this.ruleForm.Classstatus;
      }
      if (this.ruleForm.opentime) {
        params.startDateLong = this.ruleForm.opentime[0];
        params.endDateLong = this.ruleForm.opentime[1];
      }
      if (this.ruleForm.endTime) {
        params.endDateStartLong = this.ruleForm.endTime[0];
        params.endDateEndLong = this.ruleForm.endTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch(
        {
          url: "/biz/project/listPage",
          params,
          pageNum,
        },
        true
      );
      this.getTotal(params);
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.ClassstatusList = list;
    },
    //删除
    deleteproject(projectId) {
      this.doDel({
        url: "/biz/project/delete",
        msg: "你确定要删除该班级吗？",
        ps: {
          type: "post",
          data: { projectId },
        },
      });
    },
    //详情
    seeDetails(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      sessionStorage.setItem("projectStudyTimeState", row.projectStudyTimeState);
      this.$router.push({
        path: "/web/ClassdetailsList",
        query: {
          active: "first",
        },
      });
    },
    //班级列表
    jumpclassList(row) {
      console.log(row.auditState)
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      sessionStorage.setItem("projectStudyTimeState", row.projectStudyTimeState);
      this.$router.push({
        path: "/web/ClassdetailsList",
        query: {
          active: "second",
        },
      });
    },
    //跳转学员列表
    jumpStudentList(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      sessionStorage.setItem("projectStudyTimeState", row.projectStudyTimeState);
      this.$router.push({
        path: "/web/ClassdetailsList",
        query: {
          active: "three",
        },
      });
    },
    //开班申请
    keeprecord(projectId, projectSource, courseCount, row) {
      this.projectId = projectId;
      console.log(Number(courseCount));
      if (Number(courseCount) < 1) {
        this.$confirm("你的班级还未配置课程，请先为班级配课", "提示", {
          confirmButtonText: "班级配课",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.jumpclassList(row);
          })
          .catch(() => {
            return;
          });
      } else {
        if (row.paraIsPopQuestion) {
          this.$post("/biz/project/affirm", { projectId })
            .then((ret) => {
              if (ret.status == 0) {
                if (ret.data) {
                  this.retData = ret.data.popList;
                  this.popNum = ret.data.popNum;
                  if (this.retData.length) {
                    this.paraIsPopDialog = true;
                    return false;
                  }
                }

                this.getData(-1);
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          if (projectSource == "20") {
            this.dialogVisible = true;
            this.Periodnum = row.projectPc || 1;
            this.getAreaId();
          } else if (projectSource == "50") {
            //山西
            this.$confirm(
              "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
              "开班申请",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$post("/biz/project/affirm", { projectId })
                  .then((ret) => {
                    if (ret.status == 0) {
                      if (ret.data) {
                        this.retData = ret.data.list;
                        this.homeworkLimit = ret.data.homeworkLimit;
                        this.qualifiedLimit = ret.data.qualifiedLimit;
                        this.unitLimit = ret.data.unitLimit;
                        // this.popNum = ret.data.popNum;
                        if (this.retData.length) {
                          this.SxDialog = true;
                          return false;
                        }
                      }
                      this.getData();
                    }
                  })
                  .catch((err) => {
                    return;
                  });
              })
              .catch(() => {
                return;
              });
          } else if (row.areaId.substring(0, 3) == '310' && row.trainTypeId == 5) {
            // 上海（区划id前三位是'310'）并且培训类型是职业技能,技能培训
            this.$confirm(
              "企业开班学习之前请务必在【自助经办平台】导入学员花名册，否则无法领取补贴。",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$confirm(
                  "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
                  "开班申请",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                  .then(() => {
                    this.$post("/biz/project/affirm", { projectId })
                      .then((ret) => {
                        if (ret.status == 0) {
                          this.$message({
                            message: "开班申请成功",
                            type: "success",
                          });
                          this.getData(-1);
                        }
                      })
                      .catch((err) => {
                        return;
                      });
                  })
                  .catch(() => {
                    return;
                  });
              })
              .catch(() => {
                return;
              });
          } else {
            this.$confirm(
              "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
              "开班申请",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$post("/biz/project/affirm", { projectId })
                  .then((ret) => {
                    if (ret.status == 0) {
                      this.$message({
                        message: "开班申请成功",
                        type: "success",
                      });
                      this.getData(-1);
                    }
                  })
                  .catch((err) => {
                    return;
                  });
              })
              .catch(() => {
                return;
              });
          }
        }
      }
    },
    getAreaId() {
      this.$post("/sys/company/admin/comp")
        .then((ret) => {
          this.areaIdCompId = ret.data.areaId;
        })
        .catch((err) => {
          return;
        });
    },
    areaIdSure() {
      this.dialogVisible = false;
      this.$confirm(
        "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
        "开班申请",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$post("/biz/project/affirm", {
            projectId: this.projectId,
            areaId: this.areaIdCompId,
            projectPc: this.Periodnum,
          })
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: "开班申请成功",
                  type: "success",
                });
                this.getData(-1);
              }
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {
          return;
        });
    },
    // 展示二维码
    payOrder(projectId, projectName) {
      this.innerVisible = true;
      this.innerTitle = projectName;
      this.projectId = projectId;
      this.$post("/biz/project/apply/key", { projectId: projectId }).then(
        (ret) => {
          this.qrcode = ret.data;
          // 使用$nextTick确保数据渲染
          this.$nextTick(() => {
            this.crateQrcode();
          });
        }
      );
    },
    // 生成二维码
    crateQrcode() {
      this.$refs.qrcode.innerHTML = "";
      this.qr = new QRCode("qrcode", {
        width: 300,
        height: 300, // 高度
        text: this.qrcode, // 二维码内容
        render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      });
      // console.log(this.qrcode)
    },
    /* 刷新二维码 */
    refreshCode() {
      this.$confirm(
        "重新生成后,以前的二维码将失效,你确定要重新生成吗？",
        "提示",
        {
          confirmButtonText: "确定",
          confirmButtonClass: "bgc-bv",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$post("/biz/project/reset/key", {
            projectId: this.projectId,
          })
            .then((ret) => {
              this.qrcode = ret.data;
              this.$message({
                type: "success",
                message: "二维码已重新生成",
              });
              // 使用$nextTick确保数据渲染
              this.$nextTick(() => {
                this.crateQrcode();
              });
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {
          return;
        });
    },
    // 关闭弹框,清除已经生成的二维码
    closeCode() {
      this.$refs.qrcode.innerHTML = "";
    },
    // 新增 编辑
    addTestPaper() {
      this.$router.push({
        path: "/web/addClassCtr",
        query: {
          projectId: null,
          stu: "add",
        },
      });
    },
    // 高级搜索弹窗
    search() {
      this.flag = !this.flag;
      this.params = {};
      this.getTableHeight();
    },
    //清空搜索条件
    resetForm() {
      this.params = {};
      this.ruleForm = {};
      this.projectCode = "";
      this.projectName = "";
    },
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
      this.$refs["docsFormnew"].resetFields();
      for (let key in this.docsFormnew) {
        this.docsFormnew[key] = false;
      }
    },
    showDocsDialog(raw) {
      this.docsDialogVisible = true;
      this.selectedItem.id = raw.projectId;
      this.getList(raw.projectId);
      this.selectedItem.name = raw.projectName;
      this.selectedItem.code = raw.projectCode;
      this.trainTypeId = raw.trainTypeId;
      this.areaId = raw.areaId;
    },
    getList(id) {
      this.$post("/run/project/archives", { projectId: id }).then((res) => {
        this.danganList = res.data;
      });
    },
    confirmDocsDialog() {
      // console.log(this.trainTypeId);
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      for (let i in this.docsFormnew) {
        if (this.docsFormnew[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName:
              this.selectedItem.name +
              "-" +
              this.danganList.find((el) => el.dictCode == i).dictValue,
            className: className,
            param: {
              projectId: this.selectedItem.id,
            },
          });
        }
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("/sys/download/asyncDownloadFile", params).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
    classDaily(row) {
      this.classImg = "";
      this.projectIdSel = row.projectId;
      this.projectNames = row.projectName;
      this.$post("/biz/subscribeProject/createSubscribeProjectQRCode", {
        projectId: row.projectId || row,
      }).then((re) => {
        if (re.status == "0") {
          this.classImg = re.data;
        }
      });
      this.$post("/biz/subscribeProject/querySingleSubscribeProjectList", {
        projectId: row.projectId || row,
      }).then((re) => {
        console.log(re);
        if (re.status == "0") {
          this.tableClassData = re.data;
        } else {
          this.tableClassData = [];
        }
      });
      this.innerClassDaily = true;

      var day3 = new Date();
      //获取当前时间戳
      // var timestamp=new Date().getTime()；
      // console.log(day3-86400000)
      // var day2 = day3-86400000
      // 设置默认时间——先转化为毫秒数，加上 24 小时的毫秒数，再转化回来
      // function setTime () {
      let t = new Date().getTime() + 24 * 60 * 60 * 1000; //24小时 * 60分钟 * 60秒 * 1000
      let d = new Date(t);
      let theMonth = d.getMonth() + 1;
      let theDate = d.getDate();
      let theHours = d.getHours();
      let theMinutes = d.getMinutes();
      let theSec = d.getSeconds();
      if (theMonth < 10) {
        theMonth = "0" + theMonth;
      }
      if (theDate < 10) {
        theDate = "0" + theDate;
      }
      if (theHours < 10) {
        theHours = "0" + theHours;
      }
      if (theMinutes < 10) {
        theMinutes = "0" + theMinutes;
      }
      if (theSec < 10) {
        theSec = "0" + theSec;
      }
      let date = d.getFullYear() + "年" + theMonth + "月" + theDate + "日";
      let time = theHours + ":" + theMinutes + ":" + theSec;
      let Spare = date + " " + time;

      this.Spare = Spare;
      // }s
      // setTime()
    },
    classDailyClose(subId, name) {
      this.$post("/biz/subscribeProject/deleteSingleSubscribeProject", {
        subId: subId,
      }).then((re) => {
        if (re.status == "0") {
          this.$message({
            message: name + "解绑成功",
            type: "success",
          });
          this.$post("/biz/subscribeProject/querySingleSubscribeProjectList", {
            projectId: this.projectIdSel,
          }).then((re) => {
            if (re.status == "0") {
              this.tableClassData = re.data;
            } else {
              this.tableClassData = [];
            }
          });
        } else {
          this.innerClassDaily = false;
        }
      });
    },
    subscribe() {
      this.subscribedialogVisible = true;
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
          // this.$message({
          //   type: "info",
          //   message: "已取消删除"
          // });
        }
      });
    },
    doeAjax() {
      const parmar = {
        userName: this.form.userName,
        idcard: this.form.idcard,
        mobile: this.form.mobile,
        projectId: this.projectIdSel,
      };
      console.log("parmar", parmar);
      this.$post("/biz/subscribeProject/bandUser", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.subscribedialogVisible = false;
            this.form = {};
            this.$post(
              "/biz/subscribeProject/querySingleSubscribeProjectList",
              {
                projectId: this.projectIdSel,
              }
            ).then((re) => {
              if (re.status == "0") {
                this.tableClassData = re.data;
              } else {
                this.tableClassData = [];
              }
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    handlesubscribeClose() {
      this.subscribedialogVisible = false;
      this.form = {
        mobile: "",
        userName: "",
        idcard: "",
      };
      this.$refs.form.resetFields();
    },
    // 班级日报 - 订阅 - 根据手机号获取返填信息
    getPhone_info() {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      let _this = this;
      if (reg.test(_this.form.mobile)) {
        _this
          .$post(
            "/teacher/mini-teacher/selectTeacherInfoByMobile",
            { mobile: _this.form.mobile },
            3000,
            true,
            2
          )
          .then((res) => {
            _this.form.userName = res.data.teacherName || "";
            _this.$forceUpdate();
          })
          .catch((err) => {
            return;
          });
      }
    },
    //延期申请
    extensionApplication(row) {
      console.log(row.endDate.split(" "));
      this.extensiondialogVisible = true;
      this.extensionForm = {
        projectName: row.projectName,
        originalEndDate: row.endDate.split(" ")[0].replaceAll("/", "-"),
        trainTypeId: row.trainTypeId,
        projectId: row.projectId,
        applicationEndDate: row.endDateNew
          ? row.endDateNew.replaceAll("/", "-")
          : "",
        projectDelayId: row.projectDelayId,
      };
      this.NameList = row.fileList || [];
      this.fileList = [];
    },
    handleExtensionClose() {
      this.extensiondialogVisible = false;
      this.extensionForm = {
        projectName: "",
        originalEndDate: "",
        applicationEndDate: "",
        projectId: "",
      };
      this.NameList = [];
      this.fileList = [];
      this.$refs.extensionform.resetFields();
    },
    getExtensionSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.extensionForm.trainTypeId == "5" ||
            this.extensionForm.trainTypeId == "15"
          ) {
            if (!this.NameList.length) {
              this.$message({
                type: "error",
                message: "请上传附件",
              });
            } else {
              this.doExtensionAjax();
            }
          } else {
            this.doExtensionAjax();
          }

          // this.$message({
          //   type: "info",
          //   message: "已取消删除"
          // });
        }
      });
    },
    //延期确定事件
    doExtensionAjax() {
      const parmar = {
        endDateOld: this.extensionForm.originalEndDate,
        endDateNew: this.extensionForm.applicationEndDate,
        fileList: this.NameList,
        projectId: this.extensionForm.projectId,
      };
      if (this.extensionForm.projectDelayId) {
        parmar.projectDelayId = this.extensionForm.projectDelayId;
      }
      this.$post("/edu/project/delay/apply", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.extensiondialogVisible = false;
            this.NameList = [];
            this.fileList = [];
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //申请延期 上传附件
    uploadChange(file, fileList) {
      let that = this;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isjpg = extension === "jpg";
      const isJPG = extension === "JPG";
      const ispng = extension === "png";
      const isPNG = extension === "PNG";
      const isPDF = extension === "PDF";
      const ispdf = extension === "pdf";
      if (!isjpg && !isJPG && !ispng && !isPNG && !isPDF && !ispdf) {
        that.$message.error("只能上传后缀是.jpg或.png或者.pdf的文件");
        return;
      }
      if (size > 3) {
        that.$message.error("文件大小不能超过3M");
        return;
      }
      that.fileList = fileList;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.NameList.push({
            delayFileName: file.name,
            delayFileKey: result.data.fileKey,
            delayFileUrl: result.data.fileURL,
            delayFileType: extension,
          });
          console.log(fileList);
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      // that.$forceUpdate();
    },
    jumpLook(item) {
      let extension = item.delayFileName.substring(
        item.delayFileName.lastIndexOf(".") + 1
      );
      this.fileType = extension;
      if (this.fileType == "pdf" || this.fileType == "PDF") {
        this.previewLoding1 = true;
        this.$nextTick(() => {
          pdf.embed(item.delayFileUrl, "#pdf-cert1");
        });
      } else if (this.fileType == "png" || this.fileType == "jpg") {
        this.previewLoding1 = true;
        this.ImgSrc = item.delayFileUrl;
      }
    },
    reomveExl(index) {
      this.NameList.splice(index, 1);
      this.fileList.splice(index, 1);
      console.log("length", this.NameList);
      this.$forceUpdate();
    },
    //快速开班
    quickStart() {
      sessionStorage.setItem("projectId", "");
      sessionStorage.setItem("projectSource", "");
      sessionStorage.setItem("key", 0);
      this.$router.push({
        path: "/web/quickAddClassCtr",
      });
    },
    // 复制班级
    copyClass(row){
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("projectSource", "");
      sessionStorage.setItem("key", 0);
      sessionStorage.setItem("goType", 'copyClass');
      this.$router.push({ 
        path: "/web/quickAddClassCtr"
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.flag == false ? 80 + 0.675 * 16 + 6 : 170 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.name != "classListCtr") return;
        sessionStorage.setItem("goType", ''); // 清除缓存-复制班级的状态
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.projectName = "";
            this.projectCode = "";
            this.ruleForm = {
              areaId: "",
              projectUser: "",
              projectUserPhone: "",
              Classstatus: "",
              opentime: "",
              endTime: "",
              Trainingtype: "",
            };
            this.params = {};
            this.getData();
            if (route.params.addId) {
              this.classDaily(route.params.addId);
            }
          } else {
            this.getData(-1);
          }
        } else if (sessionStorage.getItem("refresh") != 0) {
          sessionStorage.setItem("refresh", 0);
          this.projectName = "";
          this.projectCode = "";
          this.ruleForm = {
            areaId: "",
            projectUser: "",
            projectUserPhone: "",
            Classstatus: "",
            opentime: "",
            endTime: "",
            Trainingtype: "",
          };
          this.params = {};
          this.getData(-1);
        } else {
          this.getData();
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less">
.upload-btn {
  width: 5rem;
  height: 28px;

  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}

.el-table__body-wrapper {
  z-index: 2;
}
</style>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}

.searchbox>div .el-input {
  flex: 1;
}

.projectUser {
  min-width: 6rem !important;
}

.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 300px;
    height: 300px;
    background-color: #999; //设置白色背景色
  }
}

.leftData {
  display: flex;
  flex-direction: column;

  img {
    width: 300px;
    height: 300px;
  }

  span {
    display: flex;
    width: 300px;
  }
}

.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}

.searchBox {
  padding: 0;

  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}

.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}

.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}

.searchboxItems {
  display: flex;
  align-items: center;

  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

.operationControlCurr {
  >div {
    width: 100%;
    justify-content: space-between;
  }

  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

.el-divider--horizontal {
  margin: 13px 0;
}

.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;

  .dailyRemind-1 {
    margin-bottom: 20px;

    p {
      margin-top: 10px;
    }
  }

  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}

.el-dialogs {
  .el-cascader {
    width: 100%;
  }
}
</style>